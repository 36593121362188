import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../theme';

export const useStyles = makeStyles(() => ({
  header: {
    padding: '36px 32px',
    backgroundColor: theme.palette.background.main,
    minHeight: '5vh',
    background: 'linear-gradient(90deg, rgba(0,0,0,0) 60%, rgba(219,165,23,0.542454481792717) 160%)',
    borderBottom: '1px solid #4D4D4D',
  },

  link: {
    color: '#CACACA',
    fontSize: '16px',
    textTransform: 'capitalize',
  },

  button: {
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '18px',
    position: 'relative',
    maxWidth: '194px',
    '& svg': {
      marginRight: '8px',
    },
  },

  mobileButton: {
    width: '51px',
    height: '63px',
    '& div': {
      display: 'none',
    },
  },
  linMenu: {
    textDecoration: 'none',
    color: '#DAA10E',
  },
}));
