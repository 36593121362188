import React, { useState, useEffect, memo } from 'react';
import BigNumber from 'bignumber.js';
import { Grid, Button, InputBase, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useWeb3React } from '@web3-react/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { formatter, getTokenImg } from '../../utils/utils';
import useTokenBalance from '../../hooks/useTokenBalance';

import { useBnbPriceState } from '../../state/bnbPriceContext.js';
import usePoolTokenInfo from '../../hooks/usePoolTokenInfo.js';
import magic from '../../assets/logos/magic.svg';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 16px 20px',
    background: props => (props.gradient
        ? 'linear-gradient(270deg, rgba(218, 161, 14, 0.5) 0%, rgba(218, 161, 14, 0.21875) 36.98%, rgba(218, 161, 14, 0) 68.75%), #191919'
        : ''),
    borderRadius: '16px',
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  balance: {
    color: '#9F9F9F',
    fontSize: '14px',
  },
  balanceUsd: {
    color: props => (props.error ? '#DD3434' : '#9F9F9F'),
    fontSize: '14px',
    fontFamily: 'DM Mono',
  },
  tokenSelectButton: {
    display: 'flex',
    background: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '100px',
    padding: '6px 8px',
    minWidth: '106px',
    marginTop: '8px',
  },
  tokenButtonLabel: {
    width: '22px',
    height: '22px',
    marginRight: '6px',
  },
  input: {
    color: props => (props.error ? '#DD3434' : '#9F9F9F'),
  },
  tokenButtonImg: {
    width: '22px',
    height: '22px',
  },
  maxButton: {
    color: '#F0B90B',
    '& img': {
      marginRight: '6px',
    },
  },
  tokenSymbol: {
    color: '#F0B90B',
  },
}));

const InputToken = ({
  onDemandGreaterThanBalance,
  placeholder,
  header,
  tokenInfo,
  disabled,
  setTokenSelectList,
  error,
  gradient,
  value,
  onChange,
  valueWei,
  poolInfo,
  handleMaxValue,
  showUsdPrice,
}) => {
  const { library } = useWeb3React();
  let poolTokenInfo;
  if (poolInfo?.tokens?.find(item => item.toLowerCase() === tokenInfo.address.toLowerCase())) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    poolTokenInfo = usePoolTokenInfo(poolInfo.address, tokenInfo.address);
  }
  const balance = useTokenBalance(tokenInfo.address);
  const classes = useStyles({ gradient, error });
  const priceState = useBnbPriceState();
  const usdPrice = poolTokenInfo?.averagePrice ? poolTokenInfo.averagePrice : priceState?.[tokenInfo.priceId]?.usd;
  const [usdValue, setUsdValue] = useState(0);

  useEffect(() => {
    const bnDemandValue = Number(placeholder);
    if (onDemandGreaterThanBalance && bnDemandValue > 0) {
      const newValue = bnDemandValue > Number(balance);
      onDemandGreaterThanBalance(tokenInfo.symbol, newValue);
    }
  }, [balance, onDemandGreaterThanBalance, tokenInfo.symbol, placeholder]);

  useEffect(() => {
    if (value && usdPrice && showUsdPrice) {
      const tokenUsdValue = new BigNumber(usdPrice)
        .times(new BigNumber(value))
        .div(1e18)
        .toNumber();
      setUsdValue(tokenUsdValue);
    }
  }, [tokenInfo, usdPrice, value, showUsdPrice]);

  return (
    <Grid container alignItems="center" className={classes.container}>
      <div className={classes.flexContainer}>
        {header && <div>{header}</div>}
        <div className={classes.balance}>
          {`Balance: ${balance ? formatter.format(library.utils.fromWei(balance.toFixed(0), 'ether')) : '0'}`}
        </div>
      </div>
      <div className={classes.flexContainer}>
        <InputBase
          value={valueWei ? formatter.format(library.utils.fromWei(value, 'ether')) : value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          error={error}
          inputProps={{ 'aria-label': 'naked', className: classes.input }}
          placeholder={library && placeholder ? formatter.format(library.utils.fromWei(placeholder, 'ether')) : '0'}
        />
        {setTokenSelectList ? (
          <Button className={classes.tokenSelectButton} onClick={() => setTokenSelectList(true)}>
            <Avatar className={classes.tokenButtonLabel}>
              <img
                src={getTokenImg(tokenInfo.symbol) ? getTokenImg(tokenInfo.symbol) : tokenInfo.img}
                className={classes.tokenButtonImg}
                alt={tokenInfo.symbol}
              />
            </Avatar>
            <div>{tokenInfo.symbol}</div>
            <NavigateNextIcon />
          </Button>
        ) : (
          <div className={classes.tokenSymbol}>{tokenInfo.symbol}</div>
        )}
      </div>
      <div className={classes.flexContainer}>
        <div className={classes.balanceUsd}>{error || (showUsdPrice && `$${formatter.format(usdValue)}`)}</div>
        {handleMaxValue && (
          <Button
            className={classes.maxButton}
            onClick={() => handleMaxValue(formatter.format(library.utils.fromWei(balance.toString(), 'ether')))}
          >
            <img src={magic} alt="MAX" />
            <span>MAX</span>
          </Button>
        )}
      </div>
    </Grid>
  );
};

InputToken.defaultProps = {
  onInputGreaterThanBalance: () => {},
};

export default memo(
  InputToken,
  (prevProps, nextProps) => prevProps.tokenInfo?.address === nextProps.tokenInfo?.address
    && prevProps.placeholder === nextProps.placeholder
    && prevProps.error === nextProps.error
    && prevProps.value === nextProps.value,
);
