import { useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import BigNumber from 'bignumber.js';
import { makeContract } from '../utils/utils';

import { ADDRESS_ZERO, GAS_MULTIPLYER, MIN_MAX_CHANGE_VALUE } from '../constants/global.constants';

const useSwapBnbForAllTokensMint = (indexPoolAddress, indexPoolTokensCount, poolAmountOut, setLoader) => {
  const { account, library, chainId } = useWeb3React();
  const { addToast } = useToasts();

  const intermediaries = useMemo(() => Array.from({ length: indexPoolTokensCount }, () => ADDRESS_ZERO), [
    indexPoolTokensCount,
  ]);
  const handleSwap = useCallback(async () => {
    await (async () => {
      const contract = makeContract(
        library,
        abis.uniswapRouterMinter,
        addresses[chainId][indexPoolAddress].uniswapRouterMinter,
      );
      const maxAmountMulCoefficient = 1 + MIN_MAX_CHANGE_VALUE;
      const BNBvalue = await contract.methods
        .getMaxAmountForJoin(addresses[chainId].WBNB, intermediaries, indexPoolAddress, poolAmountOut)
        .call()
        .then(res => new BigNumber(res).multipliedBy(maxAmountMulCoefficient).toFixed(0));
      const gas = await contract.methods
        .swapETHForAllTokensAndMintExact(indexPoolAddress, intermediaries, poolAmountOut)
        .estimateGas({ from: account, value: BNBvalue })
        .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
        .catch(() => {
          addToast('Error during gas estimation', {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoader(false);
        });
      if (!gas) return;
      addToast('Waiting for transaction success...', {
        appearance: 'info',
        autoDismiss: true,
      });
      await contract.methods
        .swapETHForAllTokensAndMintExact(indexPoolAddress, intermediaries, poolAmountOut)
        .send({ from: account, value: BNBvalue, gasLimit: gas })
        .then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoader(false);
        })
        .catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          }
        });
    })();
  }, [account, addToast, chainId, indexPoolAddress, intermediaries, library, poolAmountOut, setLoader]);

  return { onSwapBnbForTokensAndMint: handleSwap };
};

export default useSwapBnbForAllTokensMint;
