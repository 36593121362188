import { addresses, abis } from '@project/contracts';
import { makeContract } from './utils';

export const getPool = (library, chainId) => makeContract(library, abis.indexpool, addresses[chainId].biT10);

export const getOracle = (library, chainId) => makeContract(library, abis.uniswapV2oracle, addresses[chainId].uniswapV2oracle);

export const getFactory = (library, chainId) => makeContract(library, abis.factory, addresses[chainId].factory);

export const getSeller = (library, chainId) => makeContract(library, abis.indexseller, addresses[chainId].indexseller);
