import {
  makeStyles,
} from '@material-ui/core/styles';
import { theme } from '../../theme';

export const useStyles = makeStyles(() => ({
    container: {
      padding: '30px',
      backgroundColor: theme.palette.background.main,
      minHeight: 'calc(100vh - 250px)',
      position: 'relative',

      '& h1': {
        color: '#FFFFFF',
      },
      },
    content: {
      '& a': {
        textDecoration: 'none',
      },
    },
}));
