import { useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import BigNumber from 'bignumber.js';
import { makeContract } from '../utils/utils';
import { ZERO_ADDRESS } from '../constants/chain.constants';
import { GAS_MULTIPLYER, MIN_MAX_CHANGE_VALUE } from '../constants/global.constants.js';

const useSwapTokensForAllTokensAndMintExact = (
  tokenIn,
  amountInMax,
  indexPoolTokens,
  indexPool,
  poolAmountOut,
  setLoader,
) => {
  const { account, library, chainId } = useWeb3React();
  const { addToast } = useToasts();

  const intermediaries = useMemo(
    () => indexPoolTokens.map(item => {
        if (
          item.toUpperCase() === tokenIn.toUpperCase()
          || item.toUpperCase() === addresses[chainId].WBNB.toUpperCase()
        ) {
          return ZERO_ADDRESS;
        }
        return addresses[chainId].WBNB;
      }),
    [indexPoolTokens, tokenIn, chainId],
  );
  const handleSwap = useCallback(async () => {
    await (async () => {
      const contract = makeContract(
        library,
        abis.uniswapRouterMinter,
        addresses[chainId][indexPool].uniswapRouterMinter,
      );
      const maxAmountMulCoefficient = 1 + MIN_MAX_CHANGE_VALUE;
      const maxAmountIn = await contract.methods
        .getMaxAmountForJoin(tokenIn, intermediaries, indexPool, poolAmountOut)
        .call()
        .then(res => new BigNumber(res).multipliedBy(maxAmountMulCoefficient).toFixed(0));
      const gas = await contract.methods
        .swapTokensForAllTokensAndMintExact(tokenIn, maxAmountIn, intermediaries, indexPool, poolAmountOut)
        .estimateGas({ from: account })
        .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
        .catch(() => {
          addToast('Error during gas estimation', {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoader(false);
        });
      if (!gas) return;
      addToast('Waiting for transaction success...', {
        appearance: 'info',
        autoDismiss: true,
      });
      await contract.methods
        .swapTokensForAllTokensAndMintExact(tokenIn, maxAmountIn, intermediaries, indexPool, poolAmountOut)
        .send({ from: account, gasLimit: gas })
        .then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoader(false);
        })
        .catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          }
        });
    })();
  }, [account, addToast, chainId, intermediaries, library, tokenIn, indexPool, poolAmountOut, setLoader]);

  return { onSwapTokensForAllTokensAndMintExact: handleSwap };
};

export default useSwapTokensForAllTokensAndMintExact;
