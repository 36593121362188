import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import useTokenInfo from './useTokenInfo';
import { useCurrentBlock } from '../state/currentBlockContext';

import useAveragePrice from './useAveragePrice';
import usePoolTokenBalance from './usePoolTokenBalance';
import usePoolTokenWeight from './usePoolTokenWeight';

import { getTokenAvatar } from '../utils/utils';
import { COMMON_BASE_TOKENS } from '../constants/chain.constants.js';

const usePoolTokenInfo = (poolAddress, poolToken) => {
    const { account, library, chainId } = useWeb3React();
    const [info, setInfo] = useState({});
    const block = useCurrentBlock();

    const { name, symbol } = useTokenInfo(poolToken);
    const tokenBalance = usePoolTokenBalance(poolAddress, poolToken);
    const tokenWeight = usePoolTokenWeight(poolAddress, poolToken);
    const averagePrice = useAveragePrice(poolToken, COMMON_BASE_TOKENS[0].priceId);

    useEffect(() => {
        const fetchInfo = async (_poolToken, _name, _symbol, _tokenBalance, _tokenWeight, _averagePrice) => {
            const avatar = getTokenAvatar(_poolToken, chainId);
            const _info = {
                address: _poolToken,
                name: _name,
                symbol: _symbol,
                balance: _tokenBalance,
                weight: _tokenWeight,
                averagePrice: _averagePrice,
                img: avatar,
            };
            setInfo(_info);
        };

        if (account && library && poolToken && name && symbol && tokenBalance && tokenWeight && averagePrice) {
            fetchInfo(poolToken, name, symbol, tokenBalance, tokenWeight, averagePrice);
        }

    }, [account, averagePrice, chainId, library, name, poolToken, symbol, tokenBalance, tokenWeight, block]);

    return info;
};

export default usePoolTokenInfo;
