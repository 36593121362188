import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { makeContract } from '../utils/utils';
import { ZERO_ADDRESS } from '../constants/chain.constants.js';

const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

const useMaxAmountForJoin = () => {
  const { library, chainId } = useWeb3React();
  return useCallback(
    async (tokenAddress, indexPoolAddress, indexPoolTokens, poolAmountOut) => {
      const intermediaries = tokenAddress === ADDRESS_ZERO || tokenAddress === addresses[chainId].WBNB
          ? Array.from({ length: indexPoolTokens.length }, () => ADDRESS_ZERO)
          : indexPoolTokens.map(item => {
              if (
                item.toUpperCase() === tokenAddress.toUpperCase()
                || item.toUpperCase() === addresses[chainId].WBNB.toUpperCase()
              ) return ZERO_ADDRESS;

              return addresses[chainId].WBNB;
            });

      const targetAddress = tokenAddress === ADDRESS_ZERO ? addresses[chainId].WBNB : tokenAddress;
      const contract = makeContract(
        library,
        abis.uniswapRouterMinter,
        addresses[chainId][indexPoolAddress].uniswapRouterMinter,
      );
      return contract.methods
        .getMaxAmountForJoin(targetAddress, intermediaries, indexPoolAddress, poolAmountOut)
        .call();
    },
    [chainId, library],
  );
};

export default useMaxAmountForJoin;
