import React, { useState, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BigNumber from 'bignumber.js';
import Button from '../../Button';
import TokenList from '../../lists/tokenlist/TokenList.component.js';
import InputToken from '../../inputs/InputToken.js';
import { useStyles } from './BurnPanel.styles.js';
import { calcAllOutGivenPoolIn } from '../../../utils/bmath.js';
import useApprove from '../../../hooks/useApprove.js';
import useExitPool from '../../../hooks/useExitPool.js';

const MultiBurn = ({ poolInfo, inputValue, inputError, handleChange, poolAllowance, handleMaxValue }) => {
  const { library } = useWeb3React();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [amounts, setAmounts] = useState(poolInfo.tokens.map(() => '0'));
  const { onExitPool } = useExitPool(
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    amounts,
    setLoader,
  );

  const inputValueWei = library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether');
  const handleAmounts = useCallback(
    (inputAmount, _poolInfo) => {
      if (_poolInfo !== undefined && _poolInfo.balances.length > 0 && inputAmount > 0) {
        const _amounts = calcAllOutGivenPoolIn(
          poolInfo.balances.map(item => new BigNumber(item.balance)),
          poolInfo.weights,
          poolInfo.totalSupply,
          inputAmount,
        );
        setAmounts(_amounts);
      }
    },
    [poolInfo.balances, poolInfo.totalSupply, poolInfo.weights],
  );
  const { onApprove } = useApprove(
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    poolInfo.address,
    setLoader,
  );

  const wrappedHandleChange = value => {
    handleChange({
      value,
      handleAmounts,
      setAmounts,
    });
  };

  const handleClickBurn = () => {
    setLoader(true);
    onExitPool();
  };

  return (
    <div>
      <div className={classes.description}>
        Multi asset burn enables you to redeem your {poolInfo.symbol} token to all underlying assets.
      </div>
      <div className={classes.inputContainerMulti}>
        <InputToken
          onChange={wrappedHandleChange}
          tokenInfo={{
            symbol: poolInfo.symbol,
            address: poolInfo.address,
          }}
          size="large"
          error={inputError}
          value={inputValue.toString()}
          gradient
          header={
            <div>
              Tokens to
              <b> burn</b>
            </div>
          }
          handleMaxValue={handleMaxValue}
        />
      </div>
      <div className={classes.arrowContainer}>
        {' '}
        <ArrowDownwardIcon fontSize="large" />
      </div>
      <div className={classes.container}>
        <div className={classes.containerTitle}>
          <div className={classes.containerTitleMain}>Redeem underlying assets</div>
          <div>Upon burn, you will receive all assets below</div>
        </div>
        <TokenList poolInfo={poolInfo} poolAmounts={amounts} />
      </div>
      {poolAllowance.toNumber() >= inputValueWei ? (
        Number(inputValue) > 0 ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            onClick={handleClickBurn}
            className={classes.mintButton}
          >
            Burn {poolInfo.symbol}
          </Button>
        ) : (
          <Button error={inputError} outLine size="large" className={classes.mintButton} disabled>
            {inputError || 'Enter an Amount'}
          </Button>
        )
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onApprove}
          className={classes.mintButton}
          loader={loader}
        >
          APPROVE
        </Button>
      )}
    </div>
  );
};

export default MultiBurn;
