import React, { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';
import { abis } from '@project/contracts';
import BigNumber from 'bignumber.js';

import { makeContract } from '../utils/utils';

const useExitPool = (poolAddress, poolAmountIn, minAmountsOut, setLoader) => {
  const { account, library } = useWeb3React();
  const { addToast } = useToasts();

  const handleExit = useCallback(
    async () => {
      const exitPool = async (
        _poolAmountIn,
        _minAmountsOut,
      ) => {
        const contract = makeContract(library, abis.indexpool, poolAddress);
        const params = [
          _poolAmountIn.toString(),
          _minAmountsOut.map(item => new BigNumber(item).multipliedBy(0.9).toFixed(0)),
        ];
        const gas = await contract.methods
          .exitPool(...params)
          .estimateGas({ from: account })
          .then(res => new BigNumber(res).multipliedBy(1.1).toFixed(0))
          .catch(() => {
            addToast('Error during gas estimation', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          });
        if (!gas) return;
        addToast('Waiting for transaction success...', {
          appearance: 'info',
          autoDismiss: true,
        });
        await contract.methods
          .exitPool(...params)
          .send({ from: account, gasLimit: gas }).then(() => {
            addToast('Transaction Success!', {
              appearance: 'success',
              autoDismiss: true,
            });
            setLoader(false);

          }).catch(err => {
            if (err.message.includes('User denied transaction signature')) {
              addToast('Denied Transaction', {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoader(false);
            } else {
              addToast('Transaction Failed', {
                appearance: 'error',
                autoDismiss: true,
              });
              setLoader(false);
            }
          });
      };

      await exitPool(
        poolAmountIn,
        minAmountsOut,
      );
    },
    [account, addToast, library, minAmountsOut, poolAddress, poolAmountIn],
  );

  return { onExitPool: handleExit };
};

export default useExitPool;
