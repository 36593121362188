import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract, getTokenAvatar } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';

const useTokenInfo = (tokenAddress) => {
    const { account, library, chainId } = useWeb3React();
    const [tokenInfo, setTokenInfo] = useState({ name: null, symbol: null });

    const block = useCurrentBlock();

    useEffect(() => {
        const fetchBalance = async () => {
            const avatar = getTokenAvatar(tokenAddress, chainId);
            const contract = makeContract(library, abis.erc20, tokenAddress);
            const [name, symbol] = await Promise.all([
                contract.methods.name().call(),
                contract.methods.symbol().call(),
            ]);
            setTokenInfo({ name, symbol, avatar });
        };

        if (account && library && tokenAddress) {
            fetchBalance();
        }
    }, [account, library, block, tokenAddress, chainId]);

    return tokenInfo;
};

export default useTokenInfo;
