import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import Card from '../../../components/Card';
import Button from '../../../components/Button';

import useCurrentTokens from '../../../hooks/useCurrentTokens';
import useTokenInfo from '../../../hooks/useTokenInfo';
import useAllTokenInfo from '../../../hooks/useAllTokenInfo.js';

import { getTokenAvatar } from '../../../utils/utils';
import { CHAIN_ID } from '../../../constants/global.constants';

import { useStyles } from './styles.module';

const PoolCard = ({ poolAddress }) => {
  const classes = useStyles();

  const tokens = useCurrentTokens(poolAddress);
  const currentTokens = useAllTokenInfo(tokens);
  const { name, symbol } = useTokenInfo(poolAddress);
  const currentPoolAvatar = getTokenAvatar(poolAddress, CHAIN_ID);

  const otherToken = useMemo(() => currentTokens?.map(item => <Avatar src={item.img} key={item.symbol} />), [
    currentTokens,
  ]);

  return (
    <Grid item>
      <Card className={classes.card}>
        <Avatar
          src={currentPoolAvatar}
          className={classes.avatar}
        />
        <div>{name}</div>
        <AvatarGroup max={5} className={classes.avatarGroup}>
          {otherToken}
        </AvatarGroup>
        <Link to={`/funds/${poolAddress}`}>
          <Button className="default-text" outLine>
            {symbol} Dashboard
          </Button>
        </Link>
      </Card>
    </Grid>
  );
};

export default PoolCard;
