import erc20Abi from "./abis/erc20.json";
import indexpoolAbi from './abis/indexpool.json'
import uniswapV2oracleAbi from './abis/oracle.json'
import sellerAbi from './abis/seller.json'
import uniswapRouterMinterAbi from './abis/uniswapRouterMinter.json'
import uniswapRouterBurnerAbi from './abis/uniswapRouterBurner.json'
import factory from './abis/factory.json'
import pairs from './abis/pairs.json'

const abis = {
  erc20: erc20Abi,
  indexpool: indexpoolAbi,
  uniswapV2oracle: uniswapV2oracleAbi,
  indexseller: sellerAbi,
  factory: factory,
  pairs: pairs,
  uniswapRouterMinter: uniswapRouterMinterAbi,
  uniswapRouterBurner: uniswapRouterBurnerAbi,
};

export default abis;
