import { useCallback, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract, isApproved } from '../utils/utils';

const useCheckIfAllApproved = (poolInfo) => {

    const { account, library } = useWeb3React();
    const [isAllApproved, setIsAllApproved] = useState(true);
    const [checkApproveStatus, setCheckApproveStatus] = useState(false);

    const fetchAllowance = useCallback(async (spender, tokenAddress) => {
        try {
            const contract = makeContract(library, abis.erc20, tokenAddress);
            return await contract.methods.allowance(account, spender).call();
        } catch (error) {
            return 0;
        }
    }, [account, library]);

    const { tokens, address } = poolInfo;

    const getApproveStatus = useCallback(async () => {
        tokens.forEach(async (token) => {
            const allowance = await fetchAllowance(address, token);
            const isTokenApproved = isApproved(allowance);
            if (!isTokenApproved) {
                setIsAllApproved(false);
                return isAllApproved;
            }
        });
        setCheckApproveStatus(true);
    }, []);

    useEffect(() => {
        if (account && library) {
            getApproveStatus();
        }

    }, [account, library, checkApproveStatus, getApproveStatus]);

    return isAllApproved;
};

export default useCheckIfAllApproved;
