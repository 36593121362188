export const CHAIN_ID = 56;
export const BSC_SCAN_URL = 'https://bscscan.com/address/';
export const NETWORK_NAME = 'binance';
export const BIT_10_SYMBOL = 'biT10';
export const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export const BNB = 'BNB';
export const WBNB = 'WBNB';

export const GAS_MULTIPLYER = 1.1; // 110%
export const MIN_MAX_CHANGE_VALUE = 0.03; // 3%
