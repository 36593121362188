import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import BigNumber from 'bignumber.js';
import { makeContract } from '../utils/utils';

const useJoinPool = (poolAddress, poolAmountOut, maxAmountsIn) => {
  const { account, library } = useWeb3React();
  const { addToast } = useToasts();

  const join = async (
    _poolAmountOut,
    _maxAmountsIn,
  ) => {
    const contract = makeContract(library, abis.indexpool, poolAddress);
    const params = [
      _poolAmountOut.toString(),
      _maxAmountsIn.map(item => new BigNumber(item).multipliedBy(1.1).toFixed(0)),
    ];
    const gas = await contract.methods
      .joinPool(...params)
      .estimateGas({ from: account })
      .then(res => new BigNumber(res).multipliedBy(1.1).toFixed(0))
      .catch(() => {
        addToast('Error during gas estimation', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
    if (!gas) return;

    addToast('Waiting for transaction success...', {
      appearance: 'info',
      autoDismiss: true,
    });
    await contract.methods
      .joinPool(...params)
      .send({ from: account, gasLimit: gas }).then(() => {
        addToast('Transaction Success!', {
          appearance: 'success',
          autoDismiss: true,
        });
      }).catch(err => {
        if (err.message.includes('User denied transaction signature')) {
          addToast('Denied Transaction', {
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          addToast('Transaction Failed', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      });
  };

  const handleJoin = React.useCallback(
    async () => {
      await join(
        poolAmountOut,
        maxAmountsIn,
      );
    },
    [account, poolAddress, poolAmountOut, maxAmountsIn],
  );

  return { onJoinPool: handleJoin };
};

export default useJoinPool;
