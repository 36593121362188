import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button as MaterialButton } from '@material-ui/core';
import { theme } from '../../theme';
import { MAX_UINT, isApproved } from '../../utils/utils';
import useApprove from '../../hooks/useApprove';
import useAllowance from '../../hooks/useAllowance';

const StyledButton = withStyles({
    root: {
        background: theme.palette.gradient.main,
        borderRadius: 3,
        border: 0,
        color: theme.palette.text.primary,
        minHeight: 38,
        padding: '0 15px',
    },
    label: {
        textTransform: 'capitalize',
    },
})(MaterialButton);

const ApproveButton = ({ poolAddress, poolTokenAddress }) => {
    const allowance = useAllowance(poolAddress, poolTokenAddress);
    const { onApprove } = useApprove(poolAddress, MAX_UINT, poolTokenAddress);
    const isTokenApproved = useMemo(() => isApproved(allowance), [allowance]);

    // React.useEffect(() => {
    //     handleApproved(poolTokenAddress, isTokenApproved);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isTokenApproved, poolTokenAddress]);

    const onApproveHandler = async () => {
        await onApprove();
        // handleApproved(poolTokenAddress, true);
    };
    return (
      <>
        {isTokenApproved
                ? (
                  <MaterialButton
                    size="small"
                    disabled
                    color="secondary"
                  >
                    Approved
                  </MaterialButton>
)
                : (
                  <StyledButton
                    size="small"
                    onClick={onApproveHandler}
                    disabled={false}
                    color="secondary"
                  >
                    Approve
                  </StyledButton>
)}

      </>
    );
};

export default ApproveButton;
