import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { useQuery } from '@apollo/client';
import { getOracle } from '../utils/contracts';

import { useBnbPriceState } from '../state/bnbPriceContext';

import { getGraphUniSwapBody } from '../constants/graph.constants';
import { SHORT_TWAP_MIN, SHORT_TWAP_MAX } from '../utils/utils';

const useTokenPrices = (poolTokens, tokenPriceId) => {
    const { account, library, chainId } = useWeb3React();
    const [balance, setBalance] = useState([]);
    const bnbPriceState = useBnbPriceState();
    const { data } = useQuery(getGraphUniSwapBody(poolTokens));
    const usdPrice = bnbPriceState?.[tokenPriceId]?.usd;

    useEffect(() => {
        if (account && library && usdPrice > 0 && data && poolTokens.length) {
            (async () => {
                const contract = getOracle(library, chainId);

                const balances = await contract.methods.computeAverageTokenPrices(
                  poolTokens,
                  SHORT_TWAP_MIN,
                  SHORT_TWAP_MAX,
                ).call().then(balancesArray => (
                  balancesArray.map((item, index) => ({
                      usdPrice: new BigNumber(item).div(2 ** 112).times(usdPrice),
                      address: poolTokens[index].toLowerCase(),
                  }))
                ));

                /*  balancesGraph = data.tokens.map(item => ({
                    usdPrice: new BigNumber(item.derivedETH).times(usdPrice),
                    name: item.name,
                    address: item.id.toLowerCase(),
                })); */
                setBalance(balances);
            })();
        }
    }, [account, usdPrice, chainId, library, poolTokens, data]);

    return balance;
};

export default useTokenPrices;
