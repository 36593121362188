import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract, getTokenAvatar } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';
import { COMMON_BASE_TOKENS } from '../constants/chain.constants.js';

const useAllTokenInfo = tokenAddress => {
  const { account, library, chainId } = useWeb3React();
  const [tokenInfo, setTokenInfo] = useState([]);
  const block = useCurrentBlock();
  useEffect(() => {
    if (account && library && tokenAddress) {
      (async () => {
        if (!tokenAddress.length) {
          return;
        }
        const tokensInfo = await Promise.all(
          tokenAddress.map(async token => {
            const contract = makeContract(library, abis.erc20, token);
            const [name, symbol, decimals] = await Promise.all([
              contract.methods.name().call(),
              contract.methods.symbol().call(),
              contract.methods.decimals().call(),
            ]);
            return { name, symbol, decimals, address: token, img: getTokenAvatar(token, chainId) };
          }),
        );
        const tokenList = COMMON_BASE_TOKENS.reduce((acc, token) => {
          if (acc.find(item => item.symbol === token.symbol)) return acc;
          return [...acc, token];
        }, [...tokensInfo]);
        setTokenInfo(tokenList);
      })();
    }
  }, [account, library, block, tokenAddress, chainId]);
  return tokenInfo;
};
export default useAllTokenInfo;
