import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'none',
    height: '51px',
  },
  button: {
    height: '51px',
    width: '240px',
    border: props => (props.error ? '1px solid #DD3434' : props.disabled ? '1px solid #FFFFFF' : '1px solid #DAA10E'),
    background: props => (props.error ? 'none' : props.disabled ? 'none' : 'rgba(218, 161, 14, 0.15)'),
    borderRadius: '100px',
    textTransform: 'none',
    color: props => (props.error ? '#DD3434' : props.disabled ? '#FFFFFF' : '#DAA10E'),
    '&:hover': {
      background: props => (props.error ? 'none' : 'rgba(218, 161, 14, 0.25)'),
    },
    '&:disabled': {
      color: props => (props.error ? '#DD3434' : props.disabled ? '#FFFFFF' : '#DAA10E'),
      background: 'none',
    },
  },
}));
