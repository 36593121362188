import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import BigNumber from 'bignumber.js';
import { makeContract } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';
import { ZERO_ADDRESS } from '../constants/chain.constants.js';

const useTokenBalance = tokenAddress => {
  const { account, library } = useWeb3React();
  const [balance, setBalance] = useState();

  const block = useCurrentBlock();

  useEffect(() => {
    if (account && library && tokenAddress) {
      (async () => {
        const contract = makeContract(library, abis.erc20, tokenAddress);
        const result = tokenAddress === ZERO_ADDRESS
            ? await library.eth.getBalance(account)
            : await contract.methods.balanceOf(account).call();
        setBalance(new BigNumber(result));
      })();
    }
  }, [account, library, block, tokenAddress]);

  return balance;
};

export default useTokenBalance;
