import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, ButtonGroup, IconButton } from '@material-ui/core';
import MaterialCard from '@material-ui/core/Card';

import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MintPanel from '../../panels/mintpanel/MintPanel.component';
import BurnPanel from '../../panels/burnpanel/BurnPanel.component';

import { useStyles } from './ActionCard.styles';
import { ArrowUpIcon } from '../../../constants/icons.constants.js';

const KebabMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    document.body.addEventListener('click', handleClose);
  }, []);
  const classes = useStyles();
  const menuRef = useRef(null);
  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  const handleClose = event => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (!path.includes(menuRef.current)) {
      setShowMenu(false);
    }
  };

  return (
    <div className={classes.kebabContainer} ref={menuRef}>
      <IconButton
        className={classes.menuIcon}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {showMenu && (
        <div className={classes.kebabMenu}>
          <span>Buy/Sell on ApeSwap </span>
          <a href="https://app.apeswap.finance/swap" target="_blank" rel="noreferrer">
            {ArrowUpIcon}
          </a>
        </div>
      )}
    </div>
  );
};

const ActionCard = ({ poolInfo }) => {
  const [currentActionTab, setCurrentActionTab] = useState('MINT');
  const classes = useStyles({ currentActionTab });
  const { allTokensInfo } = poolInfo;

  return (
    <MaterialCard className={classes.card}>
      <Grid container alignItems="flex-start">
        <div className={classes.header}>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setCurrentActionTab('MINT')}
            >
              Mint
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setCurrentActionTab('BURN')}
            >
              Burn
            </Button>
          </ButtonGroup>
        </div>
        <Grid item xs={12} className={classes.content}>
          {poolInfo && allTokensInfo.length ? (
            <>
              {currentActionTab === 'MINT' && <MintPanel poolInfo={poolInfo} />}
              {currentActionTab === 'BURN' && <BurnPanel poolInfo={poolInfo} />}
            </>
          ) : (
            <div className={classes.spinner}>
              <CircularProgress color="primary" />
            </div>
          )}
        </Grid>
      </Grid>
    </MaterialCard>
  );
};

export default ActionCard;
