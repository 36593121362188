import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';

const useTotalSupply = (tokenAddress) => {
    const { account, library } = useWeb3React();
    const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
    const block = useCurrentBlock();

    useEffect(() => {

        const fetchSupply = async () => {
            const contract = makeContract(library, abis.erc20, tokenAddress);
            const value = await contract.methods.totalSupply().call();
            if (!totalSupply.eq(value)) {
                setTotalSupply(new BigNumber(value));
            }
        };

        if (account && library && tokenAddress) {
            fetchSupply();
        }
    }, [account, library, tokenAddress, totalSupply, block]);

    return totalSupply;
};

export default useTotalSupply;
