import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const usePoolTokenWeights = (poolAddress, poolTokens) => {
    const { account, library } = useWeb3React();
    const [weights, setTokenWeights] = useState([new BigNumber(0)]);

    useEffect(() => {
        if (account && library && poolTokens && poolAddress && poolTokens.length) {
            (async () => {
                const contract = makeContract(library, abis.indexpool, poolAddress);
                const newWeights = await Promise.all(poolTokens.map(async token => ({
                    weight: await contract.methods.getDenormalizedWeight(token).call(),
                    address: token.toLowerCase(),
                })));
                const newBNWeights = newWeights.map(item => ({
                    weight: new BigNumber(item.weight),
                    ...item,
                }));
                setTokenWeights(newBNWeights);
            })();
        }
    }, [account, library, poolAddress, poolTokens]);

    return weights;
};

export default usePoolTokenWeights;
