import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../theme';

export const useStyles = makeStyles({
  card: {
    height: '100%',
    backgroundColor: '#DAA10E',
    color: theme.palette.text.primary,
    width: '100%',
    borderRadius: '32px',
    background: 'linear-gradient(180deg, #DAA10E 50%, #1E1F20 100%)',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    // background: 'linear-gradient(90deg, rgba(0,0,0,0) 60%, rgba(219,165,23,0.542454481792717) 160%)',
    // borderBottom: theme.palette.border.color,
  },
  buttonGroup: {
    width: '50%',
  },
  button: {
    display: 'flex',
    fontSize: '18px',
    flexWrap: 'nowrap',
    textTransform: 'none',
    // width: '100%',
    padding: '5px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60px',
    color: 'black',
    '&:nth-child(1)': {
      fontSize: props => (props.currentActionTab === 'MINT' ? '24px' : '18px'),
      fontWeight: props => (props.currentActionTab === 'MINT' ? 700 : 500),
      opacity: props => (props.currentActionTab === 'MINT' ? 1 : 0.5),
      '&:hover': {
        opacity: 1,
      },
    },
    '&:nth-child(2)': {
      fontSize: props => (props.currentActionTab === 'BURN' ? '24px' : '18px'),
      fontWeight: props => (props.currentActionTab === 'BURN' ? 700 : 500),
      opacity: props => (props.currentActionTab === 'BURN' ? 1 : 0.5),
      '&:hover': {
        opacity: 1,
      },
    },
  },
  content: {
    padding: '32px',
    minWidth: '100%',
    color: theme.palette.text.main,
    backgroundColor: '#1E1F20',
    borderRadius: '32px',
  },
  nowrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  difference: {
    color: `${theme.palette.green.main} !important`,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  kebabContainer: {
    position: 'relative',
  },
  kebabMenu: {
    position: 'absolute',
    top: '40px',
    right: '20px',
    width: '222px',
    height: '58px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#4D4D4D',
    borderRadius: '16px 4px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        '& path': {
          stroke: '#fff',
        },
      },
    },
  },
  menuIcon: {
    color: '#191919',
    '&:hover': {
      color: '#191919',
      opacity: 1,
    },
    '& svg': {
      color: '#191919',
      '&:hover': {
        color: 'white',
      },
    },
  },
});
