import { gql } from '@apollo/client';

export const getGraphUniSwapBody = array => (
  gql`query GetPrice {
        tokens(where: {id_in: [${array?.map(item => `"${item?.toLowerCase()}"`)}]}) {
        name,
        derivedETH,
        id,
      }}`
);
