import React, { useState, useMemo, useEffect } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BigNumber from 'bignumber.js';
import { addresses, abis } from '@project/contracts';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useWeb3React } from '@web3-react/core';

import Button from '../../Button';
import InputToken from '../../inputs/InputToken.js';
import TokenListItem from '../tokenListItem.js';

import { MAX_UINT, isApproved, makeContract } from '../../../utils/utils.js';
import useAllowance from '../../../hooks/useAllowance.js';
import useApprove from '../../../hooks/useApprove.js';
import useSwapTokensForAllTokensAndMintExact from '../../../hooks/swapTokensForAllTokensAndMintExact.js';
import useSwapBnbForAllTokensMint from '../../../hooks/useSwapBnbForAllTokensMint.js';
import useMaxAmountForJoin from '../../../hooks/useMaxAmountForJoin.js';
import useTokenBalance from '../../../hooks/useTokenBalance';

import { COMMON_BASE_TOKENS } from '../../../constants/chain.constants.js';
import { BNB, WBNB } from '../../../constants/global.constants.js';

import { useStyles } from './MintPanel.styles.js';

const RouterMint = ({ poolInfo, inputValue, setInputValue, handleChange, inputError }) => {
  const { library, chainId } = useWeb3React();
  const [mintWithThisToken, setMintWithThisToken] = useState(COMMON_BASE_TOKENS[0]);
  const [loader, setLoader] = useState(false);
  const [tokenSelectList, setTokenSelectList] = useState(false);
  const onMaxAmountForJoin = useMaxAmountForJoin();
  const currentTokenBalance = useTokenBalance(mintWithThisToken.address);
  const tokenList = useMemo(() => poolInfo.allTokensInfo.filter(item => !poolInfo.tokens.includes(item.address)), [
    poolInfo.allTokensInfo,
    poolInfo.tokens,
  ]);
  const [isDemandGreaterThanBalance, setIsDemandGreaterThanBalance] = useState(null);
  const balance = useTokenBalance(mintWithThisToken.address);
  const [potentialBnbSwappedAmount, setPotentialBnbSwappedAmount] = useState(new BigNumber(0));
  const classes = useStyles();

  const allowance = useAllowance(addresses[chainId][poolInfo.address].uniswapRouterMinter, mintWithThisToken.address);

  const { onApprove } = useApprove(
    addresses[chainId][poolInfo.address].uniswapRouterMinter,
    MAX_UINT,
    mintWithThisToken.address,
    setLoader,
  );
  const isTokenApproved = useMemo(() => isApproved(allowance), [allowance]);
  const handleMintWithToken = token => {
    setMintWithThisToken(token);
    setInputValue('');
    setPotentialBnbSwappedAmount(new BigNumber(0));
    setTokenSelectList(false);
  };
  const onApproveHandler = async () => {
    setLoader(true);
    await onApprove();
  };

  const { onSwapTokensForAllTokensAndMintExact } = useSwapTokensForAllTokensAndMintExact(
    mintWithThisToken.address,
    potentialBnbSwappedAmount.toString(),
    poolInfo.tokens,
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    setLoader,
  );

  const { onSwapBnbForTokensAndMint } = useSwapBnbForAllTokensMint(
    poolInfo.address,
    poolInfo.tokens.length,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    setLoader,
  );

  const wrappedHandleChange = value => {
    handleChange({ value, setPotentialBnbSwappedAmount });
  };
  const handleMint = () => {
    setLoader(true);
    if (mintWithThisToken.symbol === BNB || mintWithThisToken.symbol === WBNB) {
      onSwapBnbForTokensAndMint();
    } else {
      onSwapTokensForAllTokensAndMintExact();
    }
  };

  useEffect(() => {
    if (inputValue) {
      (async () => {
        const result = await onMaxAmountForJoin(
          mintWithThisToken.address,
          poolInfo.address,
          poolInfo.tokens,
          library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
        ).then(res => new BigNumber(res));
        setPotentialBnbSwappedAmount(result);
        if (result.isGreaterThan(balance)) {
          setIsDemandGreaterThanBalance('Insufficient balance');
        } else {
          setIsDemandGreaterThanBalance(null);
        }
      })();
    }
  }, [
    inputValue,
    mintWithThisToken.address,
    poolInfo.address,
    poolInfo.tokens,
    library.utils,
    onMaxAmountForJoin,
    balance,
  ]);

  return (
    <div>
      <div className={classes.description}>
        Use any single asset listed to mint the {poolInfo.symbol} token in one transaction.
      </div>
      <div className={classes.container}>
        {!tokenSelectList ? (
          <>
            <div className={classes.inputContainerTop}>
              <InputToken
                onChange={wrappedHandleChange}
                tokenInfo={{
                  symbol: poolInfo.symbol,
                  address: poolInfo.address,
                }}
                size="large"
                error={inputError}
                value={inputValue.toString()}
                gradient
                header={
                  <div>
                    Tokens to<b> mint</b>{' '}
                  </div>
                }
              />
            </div>
            <div className={classes.arrowContainer}>
              <ArrowUpwardIcon fontSize="large" />
            </div>
            <InputToken
              poolInfo={poolInfo}
              tokenInfo={mintWithThisToken}
              size="large"
              disabled
              valueWei
              error={isDemandGreaterThanBalance}
              header={
                <span>
                  <b>Mint</b> with
                </span>
              }
              value={potentialBnbSwappedAmount.toFixed(0)}
              tokenSelectList={tokenSelectList}
              setTokenSelectList={setTokenSelectList}
              showUsdPrice
            />
          </>
        ) : (
          <div>
            <Button className={classes.backButton}>
              <NavigateBeforeIcon onClick={() => setTokenSelectList(false)} />
            </Button>
            <div className={classes.selectContainer}>
              {tokenList.map(token => (
                <TokenListItem
                  token={token}
                  key={token.address}
                  poolInfo={poolInfo}
                  handleMintWithToken={handleMintWithToken}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {Number(inputValue) > 0 ? (
        isTokenApproved || mintWithThisToken.symbol === BNB ? (
          <Button
            variant="contained"
            outLine={Number(inputValue) > 0 && isDemandGreaterThanBalance}
            color="primary"
            size="large"
            onClick={handleMint}
            loader={loader}
            disabled={!Number(currentTokenBalance) || isDemandGreaterThanBalance}
            className={classes.mintButton}
            error={Number(inputValue) > 0 && isDemandGreaterThanBalance}
          >
            {Number(inputValue) > 0 && isDemandGreaterThanBalance ? 'Insufficient balance' : `Mint ${poolInfo.symbol}`}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            className={classes.mintButton}
            onClick={onApproveHandler}
          >
            Approve {mintWithThisToken.symbol}
          </Button>
        )
      ) : (
        <Button outLine size="large" className={classes.mintButton} disabled>
          Enter an Amount
        </Button>
      )}
    </div>
  );
};

export default RouterMint;
