import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';

const useTotalWeight = (poolAddress) => {
    const { account, library, chainId } = useWeb3React();
    const [totalWeight, setTotalWeight] = useState(new BigNumber(0));
    const block = useCurrentBlock();

    useEffect(() => {
        const fetchTotalWeight = async (_poolAddress) => {
            const contract = makeContract(library, abis.indexpool, _poolAddress);
            const value = await contract.methods.getTotalDenormalizedWeight().call();
            if (!totalWeight.eq(value)) {
                setTotalWeight(new BigNumber(value));
            }
        };

        if (account && library && chainId && poolAddress) {
            fetchTotalWeight(poolAddress);
        }

    }, [account, chainId, library, poolAddress, totalWeight, block]);

    return totalWeight;
};

export default useTotalWeight;
