import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const usePoolTokenBalances = (poolAddress, poolTokens) => {
  const { account, library } = useWeb3React();
  const [balance, setBalance] = useState([]);

  useEffect(() => {
    if (account && library && poolTokens.length) {
      (async () => {
        const contract = makeContract(library, abis.indexpool, poolAddress);
        const balances = await Promise.all(poolTokens.map(async token => ({
          balance: await contract.methods.getBalance(token).call(),
          address: token.toLowerCase(),
        })));
        const BNBalances = balances.map(item => ({
          balance: new BigNumber(item.balance),
          ...item,
        }));
        setBalance(BNBalances);
      })();
    }
  }, [account, library, poolAddress, poolTokens]);

  return balance;
};

export default usePoolTokenBalances;
