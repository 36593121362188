import React from 'react';
import classNames from 'classnames';
import MaterialCard from '@material-ui/core/Card';

import { useStyles } from './styles.module';

const Card = ({ children, className }) => {
  const classes = useStyles();
  return (
    <MaterialCard className={classNames(classes.card, className)}>{children}</MaterialCard>
  );
};

export default Card;
