import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import BigNumber from 'bignumber.js';

import { makeContract } from '../utils/utils';

const usePoolTokenBalance = (poolAddress, tokenAddress) => {
    const { account, library, chainId } = useWeb3React();
    const [balance, setBalance] = useState();

    const fetchBalance = useCallback(async () => {
        const contract = makeContract(library, abis.indexpool, poolAddress);
        const _balance = await contract.methods.getBalance(tokenAddress).call();
        setBalance(new BigNumber(_balance));
    }, [library, poolAddress, tokenAddress]);

    useEffect(() => {

        if (account && library && tokenAddress) {
            fetchBalance();
        }

    }, [account, library, tokenAddress, chainId, fetchBalance]);

    return balance;
};

export default usePoolTokenBalance;
