import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';

const useGetSwapFee = poolAddress => {
  const { account, library, chainId } = useWeb3React();
  const [swapFee, setSwapFee] = useState(new BigNumber(0));
  const block = useCurrentBlock();

  useEffect(() => {
    const getSwapFee = async _poolAddress => {
      const contract = makeContract(library, abis.indexpool, _poolAddress);
      const value = await contract.methods.getSwapFee().call();
      if (!swapFee.eq(value)) {
        setSwapFee(new BigNumber(value));
      }
    };

    if (account && library && chainId) {
      getSwapFee(poolAddress);
    }
  }, [account, library, block, chainId, poolAddress, swapFee]);

  return swapFee;
};

export default useGetSwapFee;
