import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 9,
  },
  colorPrimary: {
    backgroundColor: '#303030',
  },
  bar: {
    borderRadius: 1,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const ProgressBar = ({ percentage }) => (
  <BorderLinearProgress variant="determinate" value={Number(percentage)} />
);

export default ProgressBar;
