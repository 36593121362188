import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';

export const useStyles = makeStyles((_theme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.dark,
  },
  header: {
    zIndex: 0,
  },
  footer: {
    zIndex: 0,
  },
}));
