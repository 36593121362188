import busd from '../assets/coins/busd.svg';
import bnb from '../assets/coins/bnb.svg';
import cake from '../assets/coins/cake.png';
import banana from '../assets/coins/banana.png';
import usdc from '../assets/coins/usdc.svg';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const FACTORY_ADDRESS_APE = '0x0841bd0b734e4f5853f0dd8d7ea041c241fb0da6';
export const FACTORY_ADDRESS_CAKE = '0xbcfccbde45ce874adcb698cc183debcf17952812';

export const COMMON_BASE_TOKENS = [
  {
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'BNB',
    name: 'Binance Coin',
    priceId: 'binancecoin',
    decimals: 18,
    img: bnb,
  },
  {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    symbol: 'BUSD',
    name: 'BUSD Token',
    priceId: 'binance-usd',
    decimals: 18,
    img: busd,
  },
  {
    address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    symbol: 'Cake',
    name: 'PancakeSwap Token',
    priceId: 'pancakeswap-token',
    decimals: 18,
    img: cake,
  },
  {
    address: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
    symbol: 'BANANA',
    name: 'ApeSwapFinance Banana',
    priceId: 'apeswap-finance',
    decimals: 18,
    img: banana,
  },
  // {
  //   address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   symbol: 'USDC',
  //   name: 'USD Coin',
  //   priceId: 'usd-coin',
  //   decimals: 18,
  //   img: usdc,
  // },
];
