import React from 'react';

import ModalWrap from '../../../components/ModalWrap';

import { useAuthMethod } from '../../../hooks/useAuth';

import { ZapIcon, MetaMaskIcon, WalletConnectIcon } from '../../../constants/icons.constants';

import { useStyles } from './styles.module';

const ConnectWalletModal = ({ onClose }) => {
  const classes = useStyles();
  const { connectMetaMask, connectWalletConnect } = useAuthMethod();

  return (
    <ModalWrap
      title="Connect to a wallet"
      onClose={onClose}
      iconTitle={ZapIcon}
    >
      <div className={classes.content}>
        <button className={classes.wallet} onClick={connectMetaMask}>
          Metamask
          <div className={classes.walletIcon}>{MetaMaskIcon}</div>
        </button>
        <button className={classes.wallet} onClick={connectWalletConnect}>
          WalletConnect
          <div className={classes.walletIcon}>{WalletConnectIcon}</div>
        </button>
      </div>
    </ModalWrap>
  );
};

export default ConnectWalletModal;
