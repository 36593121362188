import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';
import { useCurrentBlock } from '../state/currentBlockContext';

const useAllowance = (spender, tokenAddress) => {
    const { account, library } = useWeb3React();
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const block = useCurrentBlock();

    const fetchAllowance = useCallback(async () => {
        const contract = makeContract(library, abis.erc20, tokenAddress);
        const _allowance = await contract.methods.allowance(account, spender).call();
        setAllowance(new BigNumber(_allowance));
    }, [account, library, spender, tokenAddress]);

    useEffect(() => {

        if (account && library && tokenAddress && spender) {
            fetchAllowance();
        }

    }, [account, library, tokenAddress, block, spender, fetchAllowance]);

    return allowance;
};

export default useAllowance;
