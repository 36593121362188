import { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const useTokenWeight = (poolAddress, tokenAddress) => {
    const { account, library, chainId } = useWeb3React();
    const [tokenWeight, setTokenWeight] = useState();

    const fetchWeight = useCallback(async () => {
        const contract = makeContract(library, abis.indexpool, poolAddress);
        const _tokenWeight = await contract.methods.getDenormalizedWeight(tokenAddress).call();
        setTokenWeight(new BigNumber(_tokenWeight));
    }, [library, poolAddress, tokenAddress]);

    useEffect(() => {
        if (account && library && tokenAddress) {
            fetchWeight();
        }
    }, [account, library, tokenAddress, chainId, fetchWeight]);

    return tokenWeight;
};

export default useTokenWeight;
