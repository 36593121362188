import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    card: {
      borderRadius: '32px',
      boxShadow: '0px 15px 32px rgba(0, 0, 0, 0.25)',
      border: '1px solid #4D4D4D',
      background: '#191919',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
  }
));
