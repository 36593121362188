import React, { useState, useMemo, useCallback, useEffect } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BigNumber from 'bignumber.js';
import { addresses } from '@project/contracts';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useWeb3React } from '@web3-react/core';

import Button from '../../Button';
import InputToken from '../../inputs/InputToken.js';
import TokenListItem from '../tokenListItem.js';

import { isApproved } from '../../../utils/utils.js';
import { calcSingleOutGivenPoolIn, calcPoolOutGivenSingleIn } from '../../../utils/bmath.js';

import usePoolTokenInfo from '../../../hooks/usePoolTokenInfo.js';
import useSwapBnbForAllTokensBurn from '../../../hooks/useSwapBnbForAllTokensBurn.js';
import useBurnForAllTokensAndSwapForTokens from '../../../hooks/swapTokensForAllTokensAndBurn.js';
import useAllowance from '../../../hooks/useAllowance.js';
import useApprove from '../../../hooks/useApprove.js';

import { COMMON_BASE_TOKENS } from '../../../constants/chain.constants.js';
import { BNB, WBNB } from '../../../constants/global.constants';

import { useStyles } from './BurnPanel.styles.js';
import useMinAmountForBurn from '../../../hooks/useMinAmountForBurn.js';

const RouterBurn = ({ poolInfo, inputValue, setInputValue, handleChange, inputError, handleMaxValue }) => {
  const { library, chainId } = useWeb3React();
  const classes = useStyles();
  const [burnWithThisToken, setBurnWithThisToken] = useState(COMMON_BASE_TOKENS[0]);
  const [loader, setLoader] = useState(false);
  const [tokenSelectList, setTokenSelectList] = useState(false);
  const [potentialBnbSwappedAmount, setPotentialBnbSwappedAmount] = useState(new BigNumber(0));
  const onMinAmountForBurn = useMinAmountForBurn();
  const bnbTokenInfo = usePoolTokenInfo(poolInfo.address, addresses[chainId].WBNB);
  const [amounts, setAmounts] = useState(poolInfo.tokens.map(() => '0'));

  const allowance = useAllowance(addresses[chainId][poolInfo.address].uniswapRouterBurner, poolInfo.address);
  const { onApprove } = useApprove(
    addresses[chainId][poolInfo.address].uniswapRouterBurner,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    poolInfo.address,
    setLoader,
  );

  const isTokenApproved = useMemo(
    () => isApproved(allowance, library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')),
    [allowance, inputValue, library.utils],
  );

  const handleMintWithToken = token => {
    setBurnWithThisToken(token);
    setInputValue('');
    setPotentialBnbSwappedAmount(new BigNumber(0));
    setTokenSelectList(false);
  };
  const onApproveHandler = async () => {
    setLoader(true);
    await onApprove();
  };

  const { onBurnForAllTokensAndSwapForTokens } = useBurnForAllTokensAndSwapForTokens(
    poolInfo.address,
    amounts,
    poolInfo.tokens,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    burnWithThisToken.address,
    setLoader,
  );

  const { onSwapBnbForTokensAndBurn } = useSwapBnbForAllTokensBurn(
    poolInfo.address,
    amounts,
    poolInfo.tokens.length,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    setLoader,
  );

  const handleAmounts = useCallback(
    (inputAmount, _poolInfo) => {
      if (
        _poolInfo !== undefined
        && _poolInfo.balances.length > 0
        && inputAmount > 0
        && bnbTokenInfo.balance !== undefined
      ) {
        const _amountSingle = calcSingleOutGivenPoolIn(
          new BigNumber(bnbTokenInfo.balance),
          new BigNumber(bnbTokenInfo.weight),
          new BigNumber(poolInfo.totalSupply),
          new BigNumber(poolInfo.totalWeight),
          new BigNumber(library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether')),
          new BigNumber(poolInfo.swapFee),
        );
        if (!Number.isNaN(_amountSingle.toNumber())) {
          const _amounts = calcPoolOutGivenSingleIn(
            new BigNumber(bnbTokenInfo.balance),
            new BigNumber(bnbTokenInfo.weight),
            new BigNumber(poolInfo.totalSupply),
            new BigNumber(poolInfo.totalWeight),
            _amountSingle,
            new BigNumber(poolInfo.swapFee),
          );
          setAmounts(_amounts);
          setPotentialBnbSwappedAmount(_amountSingle);
        }
      }
    },
    [
      bnbTokenInfo.balance,
      bnbTokenInfo.weight,
      inputValue,
      library.utils,
      poolInfo.swapFee,
      poolInfo.totalSupply,
      poolInfo.totalWeight,
    ],
  );

  const handleBurn = () => {
    setLoader(true);
    if (burnWithThisToken.symbol === BNB || burnWithThisToken.symbol === WBNB) {
      onSwapBnbForTokensAndBurn();
    } else {
      onBurnForAllTokensAndSwapForTokens();
    }
  };

  const wrappedHandleChange = value => {
    handleChange({ value, setPotentialBnbSwappedAmount, setAmounts, handleAmounts });
  };

  useEffect(() => {
    if (Number(inputValue) > 0) {
      (async () => {
        const result = await onMinAmountForBurn(
          poolInfo.address,
          amounts,
          poolInfo.tokens,
          library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
          burnWithThisToken.address,
        );
        setPotentialBnbSwappedAmount(result);
      })();
    }
  }, [
    amounts,
    bnbTokenInfo.balance,
    bnbTokenInfo.weight,
    burnWithThisToken.address,
    inputValue,
    library.utils,
    onMinAmountForBurn,
    poolInfo.address,
    poolInfo.swapFee,
    poolInfo.tokens,
    poolInfo.totalSupply,
    poolInfo.totalWeight,
  ]);

  return (
    <div>
      <div className={classes.description}>
        Burn your {poolInfo.symbol} token directly to a single listed asset in one transaction using the underlaying
        tokens.
      </div>
      <div className={classes.container}>
        {!tokenSelectList ? (
          <>
            <div className={classes.inputContainer}>
              <InputToken
                onChange={wrappedHandleChange}
                tokenInfo={{
                  symbol: poolInfo.symbol,
                  address: poolInfo.address,
                }}
                size="large"
                error={inputError}
                value={inputValue.toString()}
                gradient
                handleMaxValue={handleMaxValue}
                header={
                  <div>
                    Tokens to <b>burn</b>
                  </div>
                }
              />
            </div>
            <div className={classes.arrowContainer}>
              {' '}
              <ArrowDownwardIcon fontSize="large" />
            </div>
            <div className={classes.inputContainerBot}>
              <InputToken
                tokenInfo={burnWithThisToken}
                size="large"
                disabled
                valueWei
                // error={inputError}
                header={
                  <div>
                    <b>Redeem</b>
                  </div>
                }
                value={potentialBnbSwappedAmount.toString()}
                tokenSelectList={tokenSelectList}
                setTokenSelectList={setTokenSelectList}
                showUsdPrice
              />
            </div>
          </>
        ) : (
          <div>
            <Button className={classes.backButton}>
              <NavigateBeforeIcon onClick={() => setTokenSelectList(false)} />
            </Button>
            <div className={classes.selectContainer}>
              {poolInfo.allTokensInfo.map(token => (
                <TokenListItem
                  token={token}
                  key={token.address}
                  poolInfo={poolInfo}
                  handleMintWithToken={handleMintWithToken}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {Number(inputValue) > 0 ? (
        isTokenApproved ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            error={inputError}
            onClick={handleBurn}
            className={classes.mintButton}
          >
            {inputError || `Burn ${poolInfo.symbol}`}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            className={classes.mintButton}
            onClick={onApproveHandler}
          >
            Approve {burnWithThisToken.symbol}
          </Button>
        )
      ) : (
        <Button outLine error={inputError} size="large" className={classes.mintButton} disabled>
          {inputError || 'Enter an Amount'}
        </Button>
      )}
    </div>
  );
};

export default RouterBurn;
