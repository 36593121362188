import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';

import InfoCard from './InfoCard';
import ActionCard from '../../components/cards/actioncard/ActionCard.component';

import usePoolInfo from '../../hooks/usePoolInfo';

import { CHAIN_ID } from '../../constants/global.constants';

import { useStyles } from './styles.module';
import Button from '../../components/Button';

const Funds = () => {
  const { address } = useParams();
  const { chainId } = useWeb3React();
  const [showActionCard, setShowActionCard] = useState(false);
  const classes = useStyles({ showActionCard });
  const poolInfo = usePoolInfo(address);

  return (
    <Grid container alignItems="flex-start" justifyContent="center" className={classes.container}>
      {poolInfo && chainId === CHAIN_ID ? (
        <>
          <InfoCard poolInfo={poolInfo} />
          <Grid item lg md className={classes.actionCard}>
            <ActionCard poolInfo={poolInfo} />
          </Grid>
          {showActionCard && <div className={classes.actionOverlay} />}
          {showActionCard && (
            <button className={classes.actionOverlayClose} onClick={() => setShowActionCard(false)}>
              CLOSE
            </button>
          )}
          <div className={classes.showActionButton}>
            <Button outLine onClick={() => setShowActionCard(true)}>
              Mint/Burn
            </Button>
          </div>
        </>
      ) : (
        <CircularProgress className={classes.loader} />
      )}
    </Grid>
  );
};

export default Funds;
