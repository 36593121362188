import React, { useState } from 'react';
import classNames from 'classnames';
import { Grid, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from '../../../../components/Card';

import { formatter, getTokenAvatar } from '../../../../utils/utils';
import { copyTextToClipboard } from '../../../../helpers/functions.helper';

import { CopyIcon, ArrowUpIcon } from '../../../../constants/icons.constants';
import { BSC_SCAN_URL, CHAIN_ID } from '../../../../constants/global.constants';

import { useStyles } from './styles.module';

const PoolHeader = ({ poolInfo }) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:700px)');
  const currentPoolAvatar = getTokenAvatar(poolInfo.address, CHAIN_ID);
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Grid item container justifyContent="space-between" className={classes.container} alignItems="stretch">
      <Grid
        item
        component={Card}
        xs={matches ? 12 : ''}
        className={classNames(classes.card, { [classes.mobileCard]: matches })}
      >
        <img
          src={currentPoolAvatar}
          alt="bit10"
          className={classes.avatar}
        />
        <div className={classNames(classes.text, classes.withImg)}>
          <div className={classes.bsc}>
            <div className={classes.title}>{poolInfo?.name}</div>
            <Tooltip title={isCopied ? 'Copied!' : 'Copy address to clipboard'} placement="top">
              <div
                className={classes.icon}
                onPointerLeave={() => {
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 500);
                }}
                onClick={() => {
                  copyTextToClipboard(poolInfo?.address);
                  setIsCopied(true);
                }}
              >
                {CopyIcon}
              </div>
            </Tooltip>
            <a className={classes.icon} href={`${BSC_SCAN_URL}${poolInfo.address}`} target="_blank" rel="noreferrer">
              {ArrowUpIcon}
            </a>
          </div>
          <div className={classNames(classes.price, classes.priceWithImg)}>
            <div>${formatter.format(poolInfo?.tvl.toNumber() / poolInfo?.totalSupply.div(1e18))}</div>
            {/* <div className={classes.green}> +0.21 (+2.53%)</div> */}
          </div>
          <div className={classes.supply}>Supply: {formatter.format(poolInfo?.totalSupply.div(1e18))}</div>
        </div>
      </Grid>
      <Grid
        item
        component={Card}
        xs={matches ? 12 : ''}
        className={classNames(classes.card, { [classes.mobileCard]: matches })}
      >
        <div className={classes.text}>
          <div className={classes.titleTVL}>Total Value Locked</div>
          <div className={classes.priceTVL}>
            <div>${formatter.format(poolInfo?.tvl.toNumber())}</div>
            {/* <div className={classes.percent}>+410.7%{ArrowUpGreen}</div> */}
          </div>
        </div>
      </Grid>
      {!matches && (
        <Grid item className={classes.card}>
          {/* <div className={classes.text}>
          <div className={classes.title}>Volume</div>
          <div className={classes.price}>
            <div>$9,999,420.69</div>
            <div className={classes.percent}>-10.7%{ArrowDownRed}</div>
          </div>
        </div> */}
        </Grid>
      )}
    </Grid>
  );
};

export default PoolHeader;
