import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    marginBottom: '32px',
  },
  card: {
    padding: '31px 16px',
    minWidth: '290px',
  },
  mobileCard: {
    marginBottom: '16px',
    minHeight: '127px',
  },
  avatar: {
    width: '65px',
    height: '65px',
    position: 'absolute',
    left: '16px',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  price: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '16px',
    fontWeight: '500',
  },
  priceTVL: {
    color: '#CACACA',
  },
  withImg: {
    paddingLeft: '80px',
  },
  priceWithImg: {
    margin: '10px 0',
  },
  title: {
    fontSize: '20px',
    color: '#FFF',
    fontWeight: '700',
  },
  titleTVL: {
    fontSize: '20px',
    color: '#CACACA',
    fontWeight: '700',
  },
  bsc: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginLeft: '13px',
    cursor: 'pointer',
    '&:hover': {
      '& path': {
        stroke: '#DAA10E',
      },
    },
  },

  supply: {
    fontSize: '12px',
    color: '#9F9F9F',
    fontFamily: 'DM Mono',
  },

  green: {
    color: '#38EE7B',
  },

  percent: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '19px',
    },
  },
});
