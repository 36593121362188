import React from 'react';
import { Grid } from '@material-ui/core';

import PoolContent from './PoolContent';
import PoolHeader from './PoolHeader';

const InfoCard = ({ poolInfo }) => (
  <Grid item lg={8}>
    <PoolHeader poolInfo={poolInfo} />
    <PoolContent poolInfo={poolInfo} />
  </Grid>
  );

export default InfoCard;
