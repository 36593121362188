import React, {
  createContext, useContext, useState, useEffect, useRef,
} from 'react';
import { useWeb3React } from '@web3-react/core';

const CurrentBlockContext = createContext(undefined);
const intervalSec = 10 * 1000;

export function CurrentBlockContextProvider({ children }) {
  const interval = useRef(null);
  const [currentBlockNumber, setCurrentBlockNumber] = useState(0);
  const { chainId, library } = useWeb3React();

  useEffect(() => {
    if (library) {
      const updateBlock = async () => {
        const latestBlockNumber = await library.eth.getBlockNumber();
        if (currentBlockNumber !== latestBlockNumber) {
          setCurrentBlockNumber(latestBlockNumber);
        }
      };
      if (currentBlockNumber === 0) {
        updateBlock();
      }

      interval.current = setInterval(async () => updateBlock(), intervalSec);
      return () => {
        clearInterval(interval.current);
      };
    }

  }, [currentBlockNumber, library, chainId]);

  return (
    <CurrentBlockContext.Provider value={currentBlockNumber}>
      {children}
    </CurrentBlockContext.Provider>
  );
}

export const useCurrentBlock = () => useContext(CurrentBlockContext);
