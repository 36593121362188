import { useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import BigNumber from 'bignumber.js';
import { makeContract } from '../utils/utils';
import { ZERO_ADDRESS } from '../constants/chain.constants';
import { GAS_MULTIPLYER, MIN_MAX_CHANGE_VALUE } from '../constants/global.constants.js';

const useSwapBnbForAllTokensBurn = (indexPoolAddress, minAmountsOut, indexPoolTokensCount, poolAmountIn, setLoader) => {
  const { account, library, chainId } = useWeb3React();
  const { addToast } = useToasts();

  const intermediaries = useMemo(() => Array.from({ length: indexPoolTokensCount }, () => ZERO_ADDRESS), [
    indexPoolTokensCount,
  ]);

  const handleSwap = useCallback(async () => {
    await (async () => {
      const contract = makeContract(
        library,
        abis.uniswapRouterBurner,
        addresses[chainId][indexPoolAddress].uniswapRouterBurner,
      );
      const minAmountMulCoefficient = 1 - MIN_MAX_CHANGE_VALUE;
      const minAmountOut = await contract.methods
        .burnForAllTokensAndSwapForETH(indexPoolAddress, minAmountsOut, intermediaries, poolAmountIn, '1')
        .call({ from: account })
        .then(res => new BigNumber(res).multipliedBy(minAmountMulCoefficient).toFixed(0));

      const gas = await contract.methods
        .burnForAllTokensAndSwapForETH(indexPoolAddress, minAmountsOut, intermediaries, poolAmountIn, minAmountOut)
        .estimateGas({ from: account })
        .then(res => new BigNumber(res).multipliedBy(GAS_MULTIPLYER).toFixed(0))
        .catch(() => {
          addToast('Error during gas estimation', {
            appearance: 'error',
            autoDismiss: true,
          });
          setLoader(false);
        });
      if (!gas) return;
      addToast('Waiting for transaction success...', {
        appearance: 'info',
        autoDismiss: true,
      });
      await contract.methods
        .burnForAllTokensAndSwapForETH(indexPoolAddress, minAmountsOut, intermediaries, poolAmountIn, minAmountOut)
        .send({ from: account, gasLimit: gas })
        .then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoader(false);
        })
        .catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          }
        });
    })();
  }, [addToast, library, chainId, indexPoolAddress, minAmountsOut, intermediaries, poolAmountIn, account, setLoader]);

  return { onSwapBnbForTokensAndBurn: handleSwap };
};

export default useSwapBnbForAllTokensBurn;
