import React, { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BigNumber from 'bignumber.js';
import TokenList from '../../lists/tokenlist/TokenList.component.js';
import InputToken from '../../inputs/InputToken.js';
import { useStyles } from './MintPanel.styles.js';
import { calcAllInGivenPoolOut } from '../../../utils/bmath.js';
import useJoinPool from '../../../hooks/useJoinPool.js';
import useAllBalanceAvailable from '../../../hooks/useAllBalanceAvailable.js';
import useCheckIfAllApproved from '../../../hooks/useCheckIfAllApproved.js';
import Button from '../../Button';

const MultiMint = ({ poolInfo, inputValue, inputError, handleChange }) => {
  const { library } = useWeb3React();

  const [amounts, setAmounts] = useState(poolInfo.tokens.map(() => '0'));
  const [demandBalanceMap, setDemandBalanceMap] = useState({});
  const [isMintDisabled, setIsMintDisabled] = useState(true);
  const [isDemandGreaterThanBalance, setIsDemandGreaterThanBalance] = useState(true);
  const hasBalanceAvailable = useAllBalanceAvailable(poolInfo, amounts, inputValue);
  const checkIfAllApproved = useCheckIfAllApproved(poolInfo);
  const classes = useStyles({ checkIfAllApproved });
  const { onJoinPool } = useJoinPool(
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    amounts,
  );

  const handleAmounts = useCallback((inputAmount, _poolInfo) => {
    if (_poolInfo !== undefined && _poolInfo.balances.length > 0 && inputAmount > 0) {
      const _amounts = calcAllInGivenPoolOut(
        _poolInfo.balances.map(item => new BigNumber(item.balance)),
        _poolInfo.totalSupply,
        inputAmount,
      );
      setAmounts(_amounts);
    }
  }, []);

  useEffect(() => {
    const values = Object.values(demandBalanceMap);
    if (values.length === poolInfo.tokens.length) {
      const isAnyDemandGreaterThanBalance = values.find(val => val);
      if (isAnyDemandGreaterThanBalance) {
        setIsDemandGreaterThanBalance(isAnyDemandGreaterThanBalance);
      }
    }
  }, [demandBalanceMap, poolInfo.tokens.length]);

  useEffect(() => {
    handleAmounts(library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'), poolInfo);
    setIsMintDisabled(false);
  }, [handleAmounts, inputValue, poolInfo, checkIfAllApproved, hasBalanceAvailable, library.utils]);

  const demandGreaterThanBalanceHandler = useCallback((tokenSymbol, value) => {
    setDemandBalanceMap(prevDemandBalanceMap => ({
      ...prevDemandBalanceMap,
      [tokenSymbol]: value,
    }));
  }, []);

  const wrappedHandleChange = value => {
    handleChange({
      value,
      handleAmounts,
      setAmounts,
      setIsDemandGreaterThanBalance,
    });
  };
  return (
    <div>
      <div className={classes.description}>
        Multi asset entry enables you to mint {poolInfo.symbol} according to its asset allocation.
      </div>
      <div className={classes.inputContainerMulti}>
        <InputToken
          onChange={wrappedHandleChange}
          tokenInfo={{
            symbol: poolInfo.symbol,
            address: poolInfo.address,
          }}
          size="large"
          error={inputError}
          value={inputValue.toString()}
          gradient
          header={
            <div>
              Tokens to
              <b> mint</b>
            </div>
          }
        />
      </div>
      <div className={classes.arrowContainer}>
        <ArrowUpwardIcon fontSize="large" />
      </div>
      <div className={classes.container}>
        <div className={classes.containerTitle}>
          <div className={classes.containerTitleMain}> Mint with all underlying assets</div>
          <div> You must have all assets listed below to mint</div>
          <div className={classes.approveStatus}>{checkIfAllApproved ? 'Assets approved' : 'Approve all assets'}</div>
        </div>
        <TokenList
          poolInfo={poolInfo}
          poolAmounts={amounts}
          withButton={!checkIfAllApproved}
          onDemandGreaterThanBalance={demandGreaterThanBalanceHandler}
          demandBalanceMap={demandBalanceMap}
        />
      </div>
      {Number(inputValue) > 0 ? (
        <Button
          variant="contained"
          outLine={Number(inputValue) > 0 && isDemandGreaterThanBalance}
          color="primary"
          size="large"
          disabled={isMintDisabled || !checkIfAllApproved || inputValue <= 0 || isDemandGreaterThanBalance}
          onClick={onJoinPool}
          className={classes.mintButton}
          error={Number(inputValue) > 0 && isDemandGreaterThanBalance}
        >
          {Number(inputValue) > 0 && isDemandGreaterThanBalance ? 'Insufficient balance' : `Mint ${poolInfo.symbol}`}
        </Button>
      ) : (
        <Button outLine size="large" className={classes.mintButton} disabled>
          Enter an Amount
        </Button>
      )}
    </div>
  );
};

export default MultiMint;
