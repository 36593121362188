import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Grid, AppBar, Toolbar, Button as MuButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '../../../components/Button';

import ConnectWalletModal from '../../modal/ConnectWalletModal';
import LogOutModal from '../../modal/LogOutModal';

import BiSharesLogo from '../../../assets/logos/logo.png';
import { LogIn, Wallet, ArrowDownIcon } from '../../../constants/icons.constants';
import { PATH_INDEX, LINK_PATH_HOME, LINK_PATH_ABOUT, LINK_PATH_FARMS } from '../../../constants/routes.constants';

import { useStyles } from './styles.module';

const Header = () => {
  const classes = useStyles();
  const { account } = useWeb3React();
  const [isConnectModal, setConnectModal] = useState(false);
  const [isLogOutModal, setLogOutModal] = useState(false);
  const [isDropDown, setDropDown] = useState(null);
  const matches = useMediaQuery('(max-width:960px)');

  const walletAddress = useMemo(
    () => account && `${account?.slice(0, 7)}...${account?.slice(account?.length - 4, account?.length)}`,
    [account],
  );

  useEffect(() => account && setConnectModal(false), [account]);

  return (
    <AppBar position="relative" color="transparent" elevation={1}>
      <Toolbar className={classes.header}>
        <Grid container alignItems="center">
          <Grid lg={1} md={2} xs={6} item>
            <Link to={PATH_INDEX}>
              <img src={BiSharesLogo} alt="BiShares Index" />
            </Link>
          </Grid>
          {!matches && (
            <Grid lg={3} md={4} justifyContent="space-around" container item>
              <MuButton className={classes.link} href={LINK_PATH_HOME}>
                Home
              </MuButton>
              <MuButton className={classes.link} href={LINK_PATH_ABOUT}>
                About
              </MuButton>
              <MuButton className={classes.link} href={PATH_INDEX}>
                Funds
              </MuButton>
              <MuButton className={classes.link} href={LINK_PATH_FARMS}>
                Farms
              </MuButton>
            </Grid>
          )}
          <Grid lg={8} md={5} xs={4} justifyContent="flex-end" container item>
            {account ? (
              <Button
                className={classNames(classes.button, { [classes.mobileButton]: matches })}
                onClick={() => setLogOutModal(true)}
                outLine
              >
                {Wallet}
                {matches ? '' : walletAddress}
              </Button>
            ) : (
              <Button
                className={classNames(classes.button, { [classes.mobileButton]: matches })}
                onClick={() => setConnectModal(true)}
                outLine
              >
                {LogIn}<div>Connect Wallet</div>
              </Button>
            )}
          </Grid>
          {matches && (
            <Grid xs={2} item>
              <Button
                className={classes.mobileButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={event => setDropDown(event.target)}
              >
                {ArrowDownIcon}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={isDropDown}
                keepMounted
                open={!!isDropDown}
                onClose={() => setDropDown(null)}
              >
                <a href={LINK_PATH_HOME} className={classes.linMenu}>
                  <MenuItem>Home</MenuItem>
                </a>
                <a href={LINK_PATH_ABOUT} className={classes.linMenu}>
                  <MenuItem>About</MenuItem>
                </a>
                <a href={PATH_INDEX} className={classes.linMenu}>
                  <MenuItem>Funds</MenuItem>
                </a>
                <a href={LINK_PATH_FARMS} className={classes.linMenu}>
                  <MenuItem>Farms</MenuItem>
                </a>
              </Menu>
            </Grid>
          )}
        </Grid>
        {isConnectModal && <ConnectWalletModal onClose={() => setConnectModal(false)} />}
        {isLogOutModal && <LogOutModal onClose={() => setLogOutModal(false)} title={walletAddress} account={account} />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
