import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '30px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.main,
    minHeight: '80vh',
  },
  button: {
    color: theme.palette.text.primary,
  },
  title: {
    textShadow: `0px 4px 12px ${theme.palette.secondary.main}`,
  },
  backgroundVideo: {
    position: 'fixed',
    zIndex: '-99',
    width: '100vw',
    height: '100vh',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  actionCard: {
    marginLeft: '48px',
    display: 'flex',
    justifyContent: 'center',
    // eslint-disable-next-line no-useless-computed-key
    '@media (max-width: 1024px)': {
      position: 'absolute',
      margin: '0 auto',
      top: '96px',
      zIndex: 10,
      width: '350px',
      display: props => (props.showActionCard ? 'block' : 'none'),
    },
  },
  actionOverlay: {
    display: 'none',
    '@media (max-width: 1024px)': {
      position: 'fixed',
      top: '0px',
      left: '0px',
      background: 'rgba(0, 0, 0, 0.8)',
      zIndex: 8,
      width: '100vw',
      height: '100vh',
      display: props => (props.showActionCard ? 'block' : 'none'),
    },
  },
  showActionButton: {
    display: 'none',
    '@media (max-width: 1024px)': {
      background: '#1E1F20',
      borderRadius: '100px',
      position: 'fixed',
      margin: '0 auto',
      zIndex: 5,
      display: 'block',
      bottom: '32px',
    },
  },
  actionOverlayClose: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block',
      position: 'absolute',
      top: '42px',
      right: '5%',
      color: 'white',
      marginRight: '20px',
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
      fontWeight: 'bold',
      zIndex: 100,
    },
  },
}));
