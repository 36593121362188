import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const useGetCurrentTokens = (poolAddress) => {
    const { account, library, chainId } = useWeb3React();
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        const fetchCurrentTokens = async () => {
            const contract = makeContract(library, abis.indexpool, poolAddress);
            const _tokens = await contract.methods.getCurrentTokens().call();
            setTokens(_tokens);
        };

        if (account && library && chainId) {
            fetchCurrentTokens();
        }

    }, [account, chainId, library, poolAddress]);

    return tokens;
};

export default useGetCurrentTokens;
