import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { makeContract } from '../utils/utils';
import { ZERO_ADDRESS } from '../constants/chain.constants.js';

const useMinAmountForBurn = () => {
  const { library, chainId, account } = useWeb3React();
  return useCallback(
    async (indexPoolAddress, minAmountsOut, indexPoolTokens, poolAmountIn, tokenAddress) => {
      const intermediaries = tokenAddress === ZERO_ADDRESS || tokenAddress === addresses[chainId].WBNB
          ? Array.from({ length: indexPoolTokens.length }, () => ZERO_ADDRESS)
          : indexPoolTokens.map(item => {
              if (
                item.toUpperCase() === tokenAddress.toUpperCase()
                || item.toUpperCase() === addresses[chainId].WBNB.toUpperCase()
              ) return ZERO_ADDRESS;

              return addresses[chainId].WBNB;
            });

      const targetAddress = tokenAddress === ZERO_ADDRESS ? addresses[chainId].WBNB : tokenAddress;
      const contract = makeContract(
        library,
        abis.uniswapRouterBurner,
        addresses[chainId][indexPoolAddress].uniswapRouterBurner,
      );
        const minAmountOut = await contract.methods
                .burnForAllTokensAndSwapForTokens(
                        indexPoolAddress,
                        minAmountsOut,
                        intermediaries,
                        poolAmountIn,
                        targetAddress,
                        '1',
                )
                .call({ from: account }); // calculate and NOT set in send params
      return minAmountOut;
    },
    [account, chainId, library],
  );
};

export default useMinAmountForBurn;
